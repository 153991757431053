<template>
  <dashboard>
    <el-row :gutter="15">
      <el-col :xs="24" :md="24">
        <div class="back">
          <a href="#" class="el-button el-button--info mb-3" @click.prevent="$router.go(-1)">
              <i class="el-icon-back mr-2"/>Back
          </a>
        </div>
        <el-card class="message-page">
          <div class="from-header">
            <el-row :gutter="15" type="flex" style="justify-content: flex-start">
              <el-col :xs="24" :md="12" class="align-self-center">
                <!-- <img v-for="participant in conversation.other_participants" :key="participant.id" :src="profilePhotoUrl(participant.member)" alt="..." class="rounded-circle d-inline" width="40px"> -->
                <h6 class="d-inline bold ml-3" v-text="conversation.name" />
              </el-col>
              <!--  <el-col :xs="24" :md="8" class="text-right">
                <div class="d-inline-block text-center mr-2">
                  <img src="/images/userimage.svg" alt="..." class="rounded-circle d-inline" width="30px">
                  <p class="mb-0 add-people">Nick</p>
                </div>
                <div class="d-inline-block text-center mr-2">
                  <img src="/images/userimage.svg" alt="..." class="rounded-circle d-inline" width="30px">
                  <p class="mb-0 add-people">Leo</p>
                </div>
              </el-col>
              <el-col :xs="24" :md="4" class="align-self-center">
                <a href="#"><p class="orange-text m-0 bold"><i class="el-icon-circle-plus-outline"></i> Add People</p></a>
              </el-col> -->
            </el-row>
          </div>
          <div ref="feed" class="message-box">
            <infinite-loading direction="top" @infinite="getOlderMessages">
              <div slot="no-more">&nbsp;</div>
              <div slot="no-results">&nbsp;</div>
            </infinite-loading>
            <messages-from-date
              v-for="(messagesFromDate, date) in orderedMessagesPerDate" :key="date"
              :message-streaks="messagesFromDate"
              :date="date"
            />
          </div>
          <div class="m-3 pt-3 message-text">
            <el-row :gutter="15" class="flex-center">
              <el-col :xs="16" :sm="20" :md="21">
                <el-input
                  ref="message"
                  v-model="newMessage"
                  type="textarea"
                  placeholder="Please input"
                  maxlength="500"
                  show-word-limit
                  autosize
                  @keydown.native.enter.exact="enterKeyDown"
                  @keyup.native.enter.exact="enterKeyUp"
                />
              </el-col>
              <!-- <el-col :xs="1" :sm="1" :md="1" class="text-center">
                <a href="#" @click="uploadFile"><i class="el-icon-paperclip" /></a>
              </el-col> -->
              <input ref="file_upload" type="file" hidden>
              <el-col :xs="4" :sm="4" :md="3" class="text-center">
                <el-button type="warning" :disabled="! newMessage" class="w-100" @click="send"><span class="hidden-xs">Send</span><span class="visible-xs"><i class="el-icon-s-promotion"></i></span></el-button>
              </el-col>
              <el-col :span="3" class="text-center">
                <el-popover
                  v-model="showingEnterConfig"
                  placement="left"
                  width="210"
                >
                  <el-radio v-model="sendOnEnter" :label="true" class="mb-0">Press Enter to send</el-radio>
                  <p class="small">Pressing Enter will send message</p>
                  <el-radio v-model="sendOnEnter" :label="false" class="mb-0">Click Send to send</el-radio>
                  <p class="small mb-0">Clicking Send will send message</p>
                  <el-button slot="reference" type="warning" class="enter-button" size="small" plain circle icon="el-icon-more" />
                </el-popover>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </dashboard>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import moment from 'moment'
import Dashboard from '~/components/Dashboard/Dashboard'
import MessagesFromDate from '~/components/Messenger/MessageGroups/Date'
import Conversation from '~/models/Conversation'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'

export default {
  name: 'ConversationView',

  components: {
    Dashboard,
    MessagesFromDate,
    InfiniteLoading,
  },

  mixins: [showsProfilePhoto],

  data () {
    return {
      Conversation: null,
      newMessage: '',
      messages: [],
      page: 1,
      showingEnterConfig: false,
      sendOnEnter: true,
    }
  },

  computed: {
    conversation () {
      if (! this.Conversation) {
        return {}
      }

      return this.Conversation.data
    },

    messagesPerDate () {
      // don't process if recent_messages hasn't loaded yet
      if (! this.messages) {
        return {}
      }

      // group by date
      const groupedByDate = _.groupBy(
        [...this.messages].reverse(),
        message => moment(message.created_at).format('YYYY-MM-DD'),
      )

      // group by sender
      const consecutiveMessagesPerDate = {}
      for (const date in groupedByDate) {
        let previousParticipantId = null
        let consecutiveMessagesBySender = []
        consecutiveMessagesPerDate[date] = []
        for (const message of groupedByDate[date]) {
          if (previousParticipantId === message.participant_id) {
            consecutiveMessagesBySender.push(message)
          } else {
            // the streak is broken; push the consecutive messages group to the per date holder
            if (consecutiveMessagesBySender.length > 0) {
              consecutiveMessagesPerDate[date].push(consecutiveMessagesBySender)
            }

            // reset the consecutiveMessages holder
            consecutiveMessagesBySender = []

            // add the new message
            consecutiveMessagesBySender.push(message)
          }
          // set previous participant ID
          previousParticipantId = message.participant_id
        }

        // add the last message-streak as the last group in the date
        consecutiveMessagesPerDate[date].push(consecutiveMessagesBySender)
      }

      return consecutiveMessagesPerDate
    },

    orderedMessagesPerDate () {
      const orderedMessagesPerDate = {}
      Object.keys(this.messagesPerDate).sort().forEach(date => {
        orderedMessagesPerDate[date] = this.messagesPerDate[date]
      })
      return orderedMessagesPerDate
    },
  },

  mounted () {
    this.Conversation = new Conversation(this.$route.params.conversation_id)
    this.Conversation.channel.listen('MessageSent', e => this.addMessage(e.message))
  },

  beforeDestroy () {
    this.Conversation.leave()
  },

  methods: {
    nowTimestamp () {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },

    addMessage (message) {
      this.messages.unshift(message)
      this.scrollToBottom()
    },

    enterKeyDown (event) {
      if (this.sendOnEnter) {
        event.preventDefault()
      }
    },

    enterKeyUp () {
      if (this.sendOnEnter) {
        this.send()
      }
    },

    send () {
      // if message is empty, do nothing
      if (! this.newMessage) {
        return
      }

      // create a temporary message to be replaced by the actual message after
      // it travels through a server round-trip
      const tempMessageId = 'temp-' + Math.floor(Math.random() * 10000)
      const now = this.nowTimestamp()
      const message = this.newMessage
      this.addMessage({
        conversation_id: this.conversation.conversation_id,
        created_at: now,
        updated_at: now,
        message,
        message_id: tempMessageId,
        message_type: 'text',
        participant: this.conversation.current_participant,
        participant_id: this.conversation.current_participant.participant_id,
      })
      this.newMessage = ''

      this.Conversation.sendMessage(message).then(response => {
        // replace the temporary message with the actual one
        this.messages[this.messages.findIndex(m => m.message_id === tempMessageId)] = response.data
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          this.$message.error(error.response.data.message)
        }

        // an error occurred in the server, it likely wasn't saved
        this.messages.splice(this.messages.findIndex(m => m.message_id === tempMessageId), 1)
        // put the message back in the input field
        this.newMessage = message
      })
    },

    getOlderMessages ($state) {
      this.Conversation.getMessages(this.page)
        .then(response => {
          // if there are no more new ones
          if (response.data.data.length === 0) {
            return $state.complete()
          }

          // increment page
          ++this.page

          // add to the list of messages if it doesn't exist in the message list yet
          this.messages = this.messages.concat(response.data.data.filter(message => this.messages.findIndex(m => m.message_id === message.message_id) < 0))

          // update state to loaded
          $state.loaded()
        })
    },

    scrollToBottom () {
      this.$nextTick(() => {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight
      })
    },

    uploadFile () {
      this.$refs.file_upload.click()
    },
  },
}
</script>

<style lang="scss">
  .message-page {
    border: none !important;
  }
  .message-page {
    .enter-button:active, .enter-button:focus{
      outline: none;
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .message-text{
      border-top: 1px solid #f6f6f6;
    }
    .from-header {
      padding: 10px 15px;
      border-bottom: 2px solid #E87722;
    }
    .m-row {
      img {
        display: inline;
        vertical-align: top;
      }
    }
    .me {
      text-align: right;
    }
    .me .message-card {
      margin-right: 5px;
      margin-left: 0;
      text-align: left;
    }
    .message-card{
      margin-left: 5px;
      display: inline-block;
      max-width: 70%;
      background-color: #faf8f7;
      padding: 10px 15px;
      border-radius: 15px;
    }
    .message-box {
      height: 60vh;
      overflow-y: scroll;
    }
    .message-card.consecutive {
      margin-left: 35px;
      margin-top: 10px;
    }
    .me .message-card.consecutive {
      margin-right: 35px;
    }
    .message-card p{
      line-height: 1.4;
    }
    .el-card {
      border: none;
    }
    .el-card__body {
      padding: 0;
    }
    .header {
      background-color: #E87722;
      padding: 15px;
    }
    .header h5 {
      font-weight: bold;
      font-size: 1rem;
      color: white;
      margin: 0;
      }
    .message-row {
      padding: 15px;
      border-bottom: 1px solid #f6f6f6;
    }
    .el-table-column--selection .cell {
      padding-left: 25px;
    }
    .el-checkbox {
      margin-bottom: 0;
    }
    .add-people {
      font-size: 10px; line-height: 10px;
      padding-top:2px;
    }
  }

  @media (max-width: 767px) {
    .el-button--warning {
      padding: 5px;

      i.el-icon-s-promotion {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 320px) {
    .el-button--small.is-circle {
      padding: 5px;
    }
  }
</style>
