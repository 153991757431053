<template>
  <div>
    <h5 class="text-center bold mt-3">{{ date | calendar }}</h5>
    <message-streak v-for="(ms, index) in messageStreaks" :key="index" :messages="ms" />
  </div>
</template>

<script>
import moment from 'moment'
import MessageStreak from '~/components/Messenger/MessageGroups/Streak'

export default {
  components: { MessageStreak },

  props: {
    date: {
      type: String,
      required: true,
    },

    messageStreaks: {
      type: Array,
      required: true,
    },
  },

  filters: {
    calendar (date) {
      return moment(date, 'YYYY-MM-DD').calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd',
        sameElse: 'MMM D'
      })
    }
  },
}
</script>
