<template>
  <div class="message-body p-3" :class="isMine ? 'me' : 'to'">
    <div class="m-row">
      <div class="message-line">
        <img v-if="! isMine" :src="profilePhoto" :alt="fullName" class="rounded-circle d-inline" width="30px">
        <div class="message-card">
          <p class="small mb-2">
            <span class="name bold" v-text="fullName" />
            <span class="info ml-2" v-text="time" />
          </p>
          <p style="white-space: pre-line" class="mb-1" v-html="secureShowLinks(firstMessage.message)" />
        </div>
        <img v-if="isMine" :src="profilePhoto" :alt="fullName" class="rounded-circle d-inline" width="30px">
      </div>
      <div v-for="m in messages.splice(1)" :key="m.id" class="message-line">
        <div class="message-card consecutive">
          <p style="white-space: pre-line" class="mb-1" v-html="secureShowLinks(m.message)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import getUser from '~/helpers/getUser'
import getUserType from '~/helpers/getUserType'
import showsProfilePhoto from '~/mixins/showsProfilePhoto'
import anchorme from 'anchorme'

const USER = getUser()
const MEMBER_TYPE = getUserType()

export default {
  mixins: [showsProfilePhoto],

  props: {
    messages: {
      type: Array,
      required: true,
    },
  },

  computed: {
    firstMessage () {
      return this.messages[0]
    },

    participant () {
      return this.firstMessage.participant
    },

    member () {
      return this.participant.member
    },

    isMine () {
      const participant = this.participant
      return participant.member_type === MEMBER_TYPE && participant.member_id === USER[MEMBER_TYPE + '_id']
    },

    fullName () {
      return this.isMine ? 'Me' : this.member.full_name
    },

    time () {
      return moment.utc(this.firstMessage.created_at).local().format('h:mma')
    },

    profilePhoto () {
      return this.profilePhotoUrl(this.member)
    },
  },

  methods: {
    htmlEntities (str) {
      return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
    },

    secureShowLinks (str) {
      return anchorme({
        input: this.htmlEntities(str),
        options: {
          attributes: {
            target: '_blank',
            class: 'message-link',
          },
        },
      })
    },
  },
}
</script>

<style>
#app .message-card p a.message-link {
  color: #007bff;
}
</style>
